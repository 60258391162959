import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadMovie } from "../redux/actions/selectedMovieActionCreator";
import Contact from "./Contact";
import BrandsCarousel from "./BrandsCarousel";
import { Link } from "react-router-dom";
import { loadRelation } from "../redux/actions/relationActionCreator";
import Footer from "./Footer";

const SelectedMovie = () => {    
    const movieSelected = localStorage.getItem('movieSelected');
    const moviesRel = localStorage.getItem('moviesRelated');
    const movie = JSON.parse(movieSelected);
    const movies = JSON.parse(moviesRel);
    const dispatch = useDispatch();
    const readyDom_kj = (fn) => {            //adclicker ads
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") ;
        else document.addEventListener('DOMContentLoaded', fn);
    }
    useEffect(() => {
        dispatch(loadMovie(movie));
        window.scrollTo(0,0);
        document.body.style.overflow = 'scroll';
        rate();
        //adclicker ads
       const readyDom_kj = (fn) => {
            if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") fn();
            else document.addEventListener('DOMContentLoaded', fn);        
        }
        readyDom_kj(() => {  
            const domains = ['mega.nz', 'megapaste.xyz', '1fichier.com', 'goodstream.uno'];
            //const landing = 'https://adclicker.io/url/';  //para utilizar adclicker
            //const userId = '65db5d855bbd3c27d997a6a8';
            var aList = document.querySelectorAll('a');
            aList.forEach((e) => {
              domains.forEach((d) => {
                if (e.href.indexOf(d) > -1) {
                  //let urlApi = "api=" + userId + "&url=" + encodeURIComponent(e.href);    //para utilizar adclicker
                  //e.href = landing + '#' + btoa(btoa(btoa(urlApi)));
                  e.href =  `https://uii.io/st?api=4f264d43b72f838f314d892d2a3687d0dc970655&url=${movie.download}`;
                }
              });
            });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [movie]);  
    const rate = () => {
        if (movie.vote_average > 7.5 && movie.vote_average < 9){
            document.getElementById('emoji-rating').innerHTML = '&#128512';
        }
        else if ((movie.vote_average > 9)){
            document.getElementById('emoji-rating').innerHTML = '&#128513';
        }
        else if (movie.vote_average > 6 && movie.vote_average <= 7.5){
            document.getElementById('emoji-rating').innerHTML = '&#128527';
        }
        else if (movie.vote_average > 5 && movie.vote_average <= 6){
            document.getElementById('emoji-rating').innerHTML = '&#128528';
        }
        else if (movie.vote_average > 4 && movie.vote_average <= 5){
            document.getElementById('emoji-rating').innerHTML = '&#128530';
        }
        else if (movie.vote_average > 2 && movie.vote_average <= 4){
            document.getElementById('emoji-rating').innerHTML = '&#128534';
        }
        else if (movie.vote_average <= 2){
            document.getElementById('emoji-rating').innerHTML = '&#129393';
        }
        
    }
    const toTop = () => {
        if (window.scrollY >= 100) {
          document.getElementById('top--button').classList.add('show');
        } else {
          document.getElementById('top--button').classList.remove('show');
        }
    }
    window.addEventListener("scroll", toTop);
    window.addEventListener("resize", toTop);
    
    const topFunction = () => {
      document.body.scrollTop = 0;             // For Safari
      document.documentElement.scrollTop = 0;  // For Chrome, Firefox, IE and Opera
    } 
    const handleMovieSelected = (movie) => {
        window.location.reload(true);  //refresh
        localStorage.setItem('movieSelected', JSON.stringify(movie)); //localStorage new to the related movie     
        window.scrollTo(0,0);
        const menu = document.getElementById('mainMenu');
        if (menu.classList.contains("open")) {
          document.getElementById('hamburger').classList.toggle("change"); 
        }
        menu.removeAttribute('class');
        dispatch(loadMovie(movie));
        dispatch(loadRelation(movie.relation))
        document.getElementById('top--button').classList.remove('show');
    }
    const closeMenu = () => {
        const menu = document.getElementById('mainMenu');
        if (menu.classList.contains("open")) {
          document.getElementById('hamburger').classList.toggle("change"); 
        }
        document.body.style.position = 'relative';
        document.getElementById('web-name').style.display = 'flex';
        document.getElementById('logo').style.display = 'flex';
        document.getElementById('mag-glass').style.display = 'flex';
        document.getElementById('genre-container').style.display = 'none';
        menu.removeAttribute("class");
        document.body.style.overflow = 'scroll';
    
        if (window.innerWidth < 600){
          menu.style.display = 'none';
          document.getElementById('input-movie-container').style.display = 'none';
        }
    }
    //setTimeout(window.location.reload(true), 5000);  refresh
    return (
        <>
            <div className='button--top' id='top--button' onClick={topFunction} title='Go to top'>
                <div className='primary text'>&#x21EA;</div>
                <div className='secondary text'>Top</div>
            </div>  
            <div className='movie--selected--section wipe-in' id="movie-selected-section" onClick={closeMenu}>
                <div className='movie--data--container' id='movie-data-container'>
                    <h1 className='movie__title' id='movie-title'>{movie.title}</h1>
                    <img className='movie__poster'  id='movie-poster' src={movie.picture} title={movie.title} alt='movie poster'></img>
                    <div className='synopsis'>{movie.description}</div>
                    <hr />
                    <div className="director"><strong>Director</strong>
                        {movie.director}
                    </div>
                    <div className="cast"><strong>Reparto</strong>
                        {movie.cast}
                    </div>
                    <div className="date"><strong>Estreno</strong>
                        &#128467; {movie.date}
                    </div>
                    <div className="rating"><strong>Valoración</strong>
                    <span>
                        {movie.vote_average} <span className='emoji__rating' id='emoji-rating'></span>
                    </span>
                    </div>
                    <div className="duration"><strong>Duración</strong>
                        &#128339; {movie.duration}
                    </div>
                    <div className="genre"><strong>Género </strong>
                        {movie.genre}
                    </div>
                    {movie.trailer? (
                        <div className='trailer__container'>
                            <iframe width="560" height="315" src={movie.trailer} title={movie.name} alt={movie.name} className="frame"
                                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                                allowFullScreen>
                            </iframe>
                        </div>
                        ) : (
                            <span className="trailer__not__available">Trailer no disponible</span>
                        )}
                        <div className="languages-_container">
                          <span className='sub' id='sub'
                          style={{
                          backgroundImage: `url(${movie.vose})`,}}>
                          </span>
                          <span className='esp' id='esp'
                          style={{
                          backgroundImage: `url(${movie.esp})`,
                          }}>
                          </span>
                          <span className='lat' id='lat'
                          style={{
                          backgroundImage: `url(${movie.lat})`,
                          }}
                          >
                          </span>
                        </div>
                    <a href={movie.download} className="download__button" target="_blank" 
                    id='download-button' onClick={readyDom_kj} rel="noopener noreferrer">
                        Consulta enlaces de descarga / online
                        </a>   
                </div>
            </div>
            <div className="map--relation--section">
                <div className="map--relation__container">
                    <div className="title--component--container">
                        <div className="title">Relacionados</div>
                    </div>
                    <div className="map">
                            {movies
                            .map((relationMovie) => (  
                            <div className="card--container square-in" id='popular-movie-card' key={relationMovie.id}>
                                    <div className="card square-in" id='popular-movie-card' >
                                    <Link
                                        to={'/selectedMovie'}
                                        className='linked-_card '
                                        onClick={() => handleMovieSelected(relationMovie)}
                                        type="button"
                                        >
                                            <div
                                                className="movie__picture"
                                                style={{
                                                backgroundImage: `url(${relationMovie.picture})`,
                                                }}
                                                title={relationMovie.title}
                                            >
                                                <div className="vote-_container">
                                                    <span className='vote__average'>&#11088; {relationMovie.vote_average}</span>
                                                </div>
                                                <div className="languages-_container">
                                                    <span className='sub' id='sub'
                                                    style={{
                                                    backgroundImage: `url(${relationMovie.vose})`,}}>
                                                    </span>
                                                    <span className='esp' id='esp'
                                                    style={{
                                                    backgroundImage: `url(${relationMovie.esp})`,
                                                    }}>
                                                    </span>
                                                    <span className='lat' id='lat'
                                                    style={{
                                                    backgroundImage: `url(${relationMovie.lat})`,
                                                    }}
                                                    >
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                            </div> 
                            ))}
                        </div>
                    </div>
                </div>
            <div className="contact--container">
                <div className="contact">
                    <Contact />
                    <br />
                    <BrandsCarousel />
                </div>
            </div>
         
            <Footer />

        </>
    );
};

export default SelectedMovie;